<template>
  <layout-default>
    <main class="w-9/12">
      <div>
        <img
            src="../assets/images/profile_banner.png"
            alt="Banner"
        >

      </div>
      <div>
        <div class="flex items-baseline gap-x-4 -translate-y-1/2">
          <div>
            <img
                class="w-[128px]"
                src="../assets/images/avatar.png"
                alt="Profile avatar"
            >
          </div>
          <div>
            <h1 class="text-grayscale-700 font-semibold text-sm">User name</h1>
            <p class="text-grayscale-700 font-medium text-xs">adres@email.com</p>
          </div>
        </div>
      </div>
    </main>
  </layout-default>
</template>

<script setup>
  import LayoutDefault from "@/layouts/LayoutDefault";
  import { useRouter, useRoute } from 'vue-router'

  const route = useRoute()
</script>

<style scoped>

</style>