<template>
  <layout-auth>
    <div class="col-start-2 col-end-5 my-auto">
      <div class="flex space-x-2.5 items-center">
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M5.80474 0.528575C6.06509 0.788925 6.06509 1.21103 5.80474 1.47138L2.27614 4.99998L5.80474 8.52858C6.06509 8.78892 6.06509 9.21104 5.80474 9.47138C5.54439 9.73173 5.12228 9.73173 4.86193 9.47138L0.861929 5.47138C0.601579 5.21103 0.601579 4.78892 0.861929 4.52858L4.86193 0.528575C5.12228 0.268226 5.54439 0.268226 5.80474 0.528575Z"
              fill="#878787"
          />
        </svg>
        <router-link to="/" class="text-grayscale-500 text-xs">Wróć do strony głównej</router-link>
      </div>
      <h1 class="text-grayscale-700 text-2xl font-bold mt-9">
        Lorem ipsum Dolor sit Amet, opcjonalny tekst powitalny
      </h1>
      <Form
          action=""
          class="mt-14 flex flex-col border-b border-grayscale-200 pb-10"
          @submit="handleLogin"
      >
        <div>
          <label for="email" class="text-grayscale-700 text-xs font-bold mb-2">
            E-mail
          </label>
          <Field name="email" type="email" v-model="state.email" :disabled="state.loading" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2 mb-6" />
          <ErrorMessage name="email" class="" />
        </div>

        <div>
          <label for="password" class="text-grayscale-700 text-xs font-bold mb-2">
            Hasło
          </label>
          <Field name="password" v-model="state.password" :disabled="state.loading" type="password" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2" />
          <ErrorMessage name="password" class="" />
        </div>

        <div class="form-group">
          <div v-if="state.message" class="alert alert-danger mt-2 text-error-700 text-sm font-medium" role="alert">
            {{ state.message }}
          </div>
        </div>

        <button type="submit" class="btn-primary loader mt-10" :disabled="state.loading">
          <LoadingIndicator :loading="state.loading" />
          <span>Zaloguj się</span>
        </button>
      </Form>
      <div class="mt-10 space-y-2">
        <p class="text-grayscale-700 leading-6">
          Zapomniałes hasla?
          <span class="text-primary-500 font-bold text-sm"
          >Przypomnij hasło</span
          >
        </p>
        <p class="text-grayscale-700 leading-6">
          Nie masz jeszcze konta?
          <router-link to="/register" class="text-primary-500 font-bold text-sm">
            Załóż teraz
          </router-link>
        </p>
      </div>
    </div>
    <div class="col-start-6 col-end-13">
      <img
          class="h-fit"
          src="../assets/images/mascot-logowanie.png"
          alt="Login mascot"
      />
    </div>
  </layout-auth>
</template>

<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from "axios"
import LayoutAuth from "@/layouts/LayoutAuth";
import {computed, onBeforeMount, ref} from "vue";
import { useAuth } from "@/stores/auth";
import {useRouter} from "vue-router";
import LoadingIndicator from "@/components/atoms/LoadingIndicator";

const auth = useAuth()
const router = useRouter()

const state = ref({
  email: localStorage.getItem('login:email_placeholder') ?? '',
  password: '',
  message: '',
  loading: false,
})

const rules = computed(() => ({
  name: {
    required,
    email,
  },
  password: {
    required,
  }
}))

const v$ = useVuelidate(rules, state)

onBeforeMount(() => {
  if(auth.isLoggedIn){
    router.push('/')
  }
})

const handleLogin = async (values) => {
  state.value.loading = true

  localStorage.setItem('login:email_placeholder', values.email)

  await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
    email: values.email,
    password: values.password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(({ status, data }) => {
    if(status === 200){
      const token = data.data.auth.token
      const expires = 3600 * 4
      auth.setToken({
        token: token,
        expires: expires,
      })
      state.value.loading = false
      state.value.message = data.message
      router.push('/')
    }
  }).catch(({ response }) => {
    state.value.message = response.data.message;
    state.value.loading = false
  })
}
</script>
