<template>
  <layout-default>
    <main class="w-1/2 mr-4 flex flex-col gap-y-4">
      <SinglePost :showComments="true" />
    </main>

    <RightSidebar />
  </layout-default>
</template>

<script setup>
  import { useRoute } from "vue-router";

  import LayoutDefault from "@/layouts/LayoutDefault";
  import RightSidebar from "@/components/organisms/RightSidebar";
  import SinglePost from "@/components/organisms/SinglePost";

  const route = useRoute()

</script>

<style scoped>

</style>