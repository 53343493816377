import { defineStore } from "pinia";
import { VueCookieNext } from "vue-cookie-next";

export const useAuth = defineStore('auth', {
    state: () => ({
        token: VueCookieNext.getCookie('user_token') ?? null
    }),
    actions: {
        setToken(tokenInfo){
            this.token = tokenInfo.token
            VueCookieNext.setCookie('user_token', tokenInfo.token, { expire: tokenInfo.expires })
        },
        logout() {
            VueCookieNext.removeCookie('user_token')
            this.token = null
        }
    },
    getters: {
        isLoggedIn(state){
            return state.token != null
        }
    }
})
