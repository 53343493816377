<template>
  <nav class="flex flex-row w-6/12 h-full">
    <ul
      class="w-full flex justify-around text-center font-bold text-2xl text-grayscale-700"
    >
      <li
        class="w-2/12 h-100 relative flex items-center justify-center pt-2 leading-6"
        :class="{ 'text-primary-500': item.isActive }"
        role="button"
        v-for="(item, index) in menuItems"
        :key="index"
        @click="toggleActiveCategory(item.id)"
      >
        <span class="transition-colors duration-300">{{ item.name }}</span>
        <span
          class="w-7/12 h-1 bg-primary-500 absolute bottom-0 rounded-t"
          :class="{ block: item.isActive, hidden: !item.isActive }"
        ></span>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref } from "vue";

const activeCategory = ref(1);

let menuItems = ref([
  {
    id: 0,
    isActive: false,
    name: "7D",
  },
  {
    id: 1,
    isActive: true,
    name: "48H",
  },
  {
    id: 2,
    isActive: false,
    name: "24H",
  },
]);

const toggleActiveCategory = (id) => {
  menuItems.value[activeCategory.value].isActive = false;
  activeCategory.value = id;
  menuItems.value[activeCategory.value].isActive = true;
};
</script>
<style scoped></style>
