<template>
  <div class="w-3/12 flex items-center justify-end">
    <svg
      class="mr-5 svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z"
      />
    </svg>
    <svg
      class="mr-5 svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1461 3.24812C10.4433 2.51616 11.1614 2 12 2C12.8386 2 13.5567 2.51616 13.8539 3.24812C16.8202 4.06072 19 6.77579 19 10V14.6972L20.8321 17.4453C21.0366 17.7522 21.0557 18.1467 20.8817 18.4719C20.7077 18.797 20.3688 19 20 19H15.4646C15.2219 20.6961 13.7632 22 12 22C10.2368 22 8.77806 20.6961 8.53545 19H4C3.63121 19 3.29235 18.797 3.11833 18.4719C2.94431 18.1467 2.96338 17.7522 3.16795 17.4453L5 14.6972V10C5 6.77579 7.17983 4.06072 10.1461 3.24812ZM10.5854 19C10.7913 19.5826 11.3469 20 12 20C12.6531 20 13.2087 19.5826 13.4146 19H10.5854ZM12 5C9.23858 5 7 7.23858 7 10V15C7 15.1974 6.94156 15.3904 6.83205 15.5547L5.86852 17H18.1315L17.168 15.5547C17.0584 15.3904 17 15.1974 17 15V10C17 7.23858 14.7614 5 12 5Z"
      />
    </svg>

    <div
      class="w-7/12 flex justify-center items-center z-20"
      v-if="auth.isLoggedIn"
    >
      <div class="w-full flex justify-center items-center">
        <div class="w-full relative py-3">
          <div
            class="flex justify-center items-center space-x-3 cursor-pointer"
          >
            <div class="overflow-hidden">
              <!-- <UserAvatar
                  class="w-9"
                  src="../../assets/images/avatar.png"
                  alt="Zdjęcie profilowe użytkownika:"
              /> -->
              <img
                src="../../assets/images/avatar.png"
                alt="Zdjęcie profilowe użytkownika: User"
                class="rounded-full aspect-square"
              />
            </div>
            <div
              class="font-semibold text-lg text-grayscale-700"
              @click="toggleUserPanel"
            >
              <span class="cursor-pointer">Testowy user</span>
            </div>
            <svg
              class="fill-grayscale-700"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="toggleUserPanel"
            >
              <path
                d="M5.29289 9.29289C5.68342 8.90237 6.31658 8.90237 6.70711 9.29289L12 14.5858L17.2929 9.29289C17.6834 8.90237 18.3166 8.90237 18.7071 9.29289C19.0976 9.68342 19.0976 10.3166 18.7071 10.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289Z"
              />
            </svg>
          </div>
          <div
            class="absolute border border-grayscale-50 w-full bg-white rounded-2xl shadow mt-5"
            v-show="isUserPanelActive"
          >
            <div
              class="absolute top-0 w-6 h-6 border border-grayscale-50 bg-white rounded-lg border-b-0 border-r-0 rounded-bl-none rounded-tr-none -top-[0.8rem] left-1/2 -translate-x-1/2 rotate-45"
            ></div>
            <div class="relative px-6 py-5">
              <DropdownOptions :list="profileOptionsList" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!auth.isLoggedIn">
      <router-link
        to="/login"
        class="font-semibold text-base text-grayscale-700 mr-4"
        >Zaloguj</router-link
      >
      <router-link
        to="/register"
        class="font-semibold text-base text-grayscale-700"
        >Załóż Konto</router-link
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "@/stores/auth";
import UserAvatar from "@/components/atoms/UserAvatar";
import DropdownOptions from "@/components/molecules/DropdownOptions";

const router = useRouter();
const auth = useAuth()

const userPanelOpened = ref(false);

const profileOptionsList = [
  {
    icon: "user",
    title: "Profil",
    url: "/profile/testowy_user",
  },
  {
    icon: "settings",
    title: "Ustawienia",
    url: "#",
  },
  {
    icon: "logout",
    title: "Wyloguj",
    url: "#",
    callback: () => {
      auth.logout()
    },
  },
];

const toggleUserPanel = () => {
  userPanelOpened.value = !userPanelOpened.value;
};

const isUserPanelActive = computed(() => {
  return userPanelOpened.value;
});
</script>
