import { set, keys, getAll } from "./helpers/idb";
import {defineStore} from "pinia";
import {populateFromCache, addCategories} from "@/stores/caching-defaultActions";

const DB_NAME = 'categories'

export const useCategoriesCaching = defineStore('categories-store',{
    state: () => ({
        categories: [],
    }),
    actions: {
        getCached(callback) {
            populateFromCache(DB_NAME).then(response => {
                this.categories = response
                if(callback != null){
                    if(typeof callback === "function") callback()
                    else throw new Error("Callback has to be function")
                }
            })
        },
        addCached(categories) {
            addCategories(DB_NAME, categories, this.categories)
        }
    },
})