<template>
  <select
    name="categories"
    id="multiple"
    multiple
    ref="select"
    class="create-post-bar-select"
  >
    <option
      v-for="category in categories"
      :key="category.id"
      :value="category.title"
      :data-dupa="category.id"
      class="py-3"
    >
      {{ category.title }}
    </option>
  </select>
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from "vue";
import SlimSelect from "slim-select";
import axios from "axios";

const select = ref(null);
const categories = ref([]);

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

onBeforeMount(() => {
  const getCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/categories`,
        { ...config }
      );

      categories.value = response.data.data;
      //   return response.data.data;
    } catch (e) {
      return (categories.value[0] = {
        id: "error",
        title: "Brak kategorii - wpisz własne",
      });
    }
  };

  getCategories();
});

onMounted(() => {
  new SlimSelect({
    select: select.value,
    placeholder: "Wybierz kategorie",
    searchPlaceholder: "Wyszukaj",
    addable: (value) => {
      return {
        text: value,
        id: new Date().valueOf(),
      };
    },
  });
});
</script>
