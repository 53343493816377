<template>
  <footer class="flex flex-col mt-2 pl-1">
    <div class="flex items-center justify-between">
      <span
        class="font-medium text-xs text-grayscale-800"
        data-tooltip="Memy, Humor, Śmieszne Obrazki, Pasty, Papież"
        >Tagi</span
      >
      <div class="flex items-center gap-x-2">
        <div class="flex items-center text-grayscale-500 text-sm font-medium">
          <svg
            class="mr-1 fill-grayscale-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V17C22 18.1046 21.1046 19 20 19H15.4142L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L8.58579 19H4C2.89543 19 2 18.1046 2 17V6ZM20 6H4V17H9C9.26522 17 9.51957 17.1054 9.70711 17.2929L12 19.5858L14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H20V6Z"
            />
          </svg>
          <span>125</span>
        </div>
        <div class="flex items-center text-grayscale-500 text-sm font-medium">
          <svg
            class="mr-1 fill-grayscale-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
              fill="#6E6E6E"
            />
            <path
              d="M21.8944 11.5528C19.7362 7.23635 15.9031 5 12 5C8.09687 5 4.26379 7.23635 2.10557 11.5528C1.96481 11.8343 1.96481 12.1657 2.10557 12.4472C4.26379 16.7637 8.09687 19 12 19C15.9031 19 19.7362 16.7637 21.8944 12.4472C22.0352 12.1657 22.0352 11.8343 21.8944 11.5528ZM12 17C9.03121 17 5.99806 15.3792 4.12966 12C5.99806 8.62078 9.03121 7 12 7C14.9688 7 18.0019 8.62078 19.8703 12C18.0019 15.3792 14.9688 17 12 17Z"
            />
          </svg>
          <span>125</span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-3">
      <div class="flex items-center justify-center">
        <button
          class="w-10 h-10 btn-primary flex items-center justify-center mr-2"
          type="button"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          class="w-10 h-10 btn-primary-ghost flex items-center justify-center"
          type="button"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z"
            />
          </svg>
        </button>
      </div>
      <div class="flex items-center justify-center">
        <button
          class="w-10 h-10 btn-gray flex items-center justify-center mr-2"
          type="button"
          @click="$emit('openComentBar')"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V17C22 18.1046 21.1046 19 20 19H15.4142L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L8.58579 19H4C2.89543 19 2 18.1046 2 17V6ZM20 6H4V17H9C9.26522 17 9.51957 17.1054 9.70711 17.2929L12 19.5858L14.2929 17.2929C14.4804 17.1054 14.7348 17 15 17H20V6Z"
            />
          </svg>
        </button>
        <button
          class="w-10 h-10 btn-gray-ghost flex items-center justify-center"
          type="button"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21 6C21 7.65685 19.6569 9 18 9C17.1344 9 16.3544 8.63338 15.8068 8.04695L8.95393 11.4734C8.98421 11.6444 9 11.8203 9 12C9 12.1797 8.98421 12.3557 8.95393 12.5266L15.8068 15.9531C16.3544 15.3666 17.1344 15 18 15C19.6569 15 21 16.3431 21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 17.8203 15.0158 17.6443 15.0461 17.4734L8.1932 14.0469C7.64564 14.6334 6.86563 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C6.86564 9 7.64565 9.36663 8.19322 9.95308L15.0461 6.52665C15.0158 6.35567 15 6.17968 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </footer>
</template>
