<template>
  <div class="border-t border-grayscale-200 pt-7 mt-6">
    <Form
      action=""
      @submit.prevent="handleCommentSubmit"
      :validation-schema="schema"
    >
      <label>
        <span class="font-bold text-xs">Skomentuj</span>
        <Field
          class="w-full resize-none p-2 mt-2 border border-grayscale-200 rounded-[8px] placeholder:text-grayscale-300 placeholder:font-medium placeholder:text-sm"
          placeholder="Wpisz swój komentarz tutaj..."
          v-model="comment"
          type="text"
          name="comment"
          id="comment"
          rows="3"
        ></Field>
        <ErrorMessage name="comment"></ErrorMessage>
      </label>

      <button class="btn-primary ml-auto block mt-4" type="submit">
        Dodaj komentarz
      </button>
    </Form>
  </div>
</template>

<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue";

const comment = ref("");

const schema = yup.object().shape({
  comment: yup.string(),
});

const handleCommentSubmit = (values) => {
  console.log(values);
};
</script>
