import {defineStore} from "pinia";

export const usePosts = defineStore('posts', {
    state: () => ({
        allPosts: [
            {
                id: 1,
                author: {
                    id: 17,
                    username: 'Test 1',
                    avatar: '../../assets/images/avatar.png'
                },
                title: 'Post 1',
                content: 'post post content',
                thumbnail: 'https://via.placeholder.com/512x512',
                thumbnail_alt: 'Automatycznie wygenerowany alt',
                tags: [
                    'Memy', 'Humor', 'Śmieszne Obrazki', 'Pasty', 'Papież'
                ],
                view: 125,
                comments: 125,
            },
            {
                id: 2,
                author: {
                    id: 17,
                    username: 'Test 2',
                    avatar: '../../assets/images/avatar.png'
                },
                title: 'Post 2',
                content: 'post post content',
                thumbnail: 'https://via.placeholder.com/512x512',
                thumbnail_alt: 'Automatycznie wygenerowany alt',
                tags: [
                    'Memy', 'Humor', 'Śmieszne Obrazki', 'Pasty', 'Papież'
                ],
                view: 125,
                comments: 125,
            },
            {
                id: 1,
                author: {
                    id: 17,
                    username: 'Test 3',
                    avatar: '../../assets/images/avatar.png'
                },
                title: 'Post 3',
                content: 'post post content',
                thumbnail: 'https://via.placeholder.com/512x512',
                thumbnail_alt: 'Automatycznie wygenerowany alt',
                tags: [
                    'Memy', 'Humor', 'Śmieszne Obrazki', 'Pasty', 'Papież'
                ],
                view: 125,
                comments: 125,
            },
            {
                id: 13,
                author: {
                    id: 17,
                    username: 'Test 4',
                    avatar: '../../assets/images/avatar.png'
                },
                title: 'Post 4',
                content: 'post post content',
                thumbnail: 'https://via.placeholder.com/512x512',
                thumbnail_alt: 'Automatycznie wygenerowany alt',
                tags: [
                    'Memy', 'Humor', 'Śmieszne Obrazki', 'Pasty', 'Papież'
                ],
                view: 125,
                comments: 125,
            },
            {
                id: 156,
                author: {
                    id: 17,
                    username: 'Test 5',
                    avatar: '../../assets/images/avatar.png'
                },
                title: 'Post 5',
                content: 'post post content',
                thumbnail: 'https://via.placeholder.com/512x512',
                thumbnail_alt: 'Automatycznie wygenerowany alt',
                tags: [
                    'Memy', 'Humor', 'Śmieszne Obrazki', 'Pasty', 'Papież'
                ],
                view: 125,
                comments: 125,
            }
        ],
        loadedPosts: [],
    }),
    actions: {

    },
    getters: {
        getAllPosts(state) {
            return state.allPosts;
        },
        getLoadedPosts(state) {
            return state.loadedPosts;
        }
    }
})