<template>
  <layout-default>
    <main class="w-1/2 mx-4 flex flex-col gap-y-4">
      <CreatePostBar />

      <div class="space-y-4">
        <SinglePost v-for="post in state.posts" :key="post.id" :post="post" />
      </div>
    </main>

    <RightSidebar />
  </layout-default>
</template>

<script setup>
import RightSidebar from "@/components/organisms/RightSidebar";
import CreatePostBar from "@/components/organisms/CreatePost.vue";
import SinglePost from "@/components/organisms/SinglePost";

import "../assets/scss/tailwind.scss";
import LayoutDefault from "@/layouts/LayoutDefault";

import {usePosts} from "@/stores/posts";
import {reactive} from "vue";
const postsStore = usePosts()

const state = reactive({
  posts: postsStore.getAllPosts
})
</script>
