<template>
  <article class="bg-white shadow-card p-6 rounded-lg">
    <PostAuthorBar />
    <PostContent />
    <PostFooter @openComentBar="openComents" />

    <!--    <div class="my-5 flex flex-col">-->
    <!--      <h2 class="font-bold text-xl text-black mb-2.5">-->
    <!--        Tytuł-->
    <!--      </h2>-->
    <!--      <span class="text-base font-medium text-grayscale-600 mb-2.5">-->
    <!--      Content-->
    <!--    </span>-->
    <!--      <img class="w-full" alt="" src="https://via.placeholder.com/1920x1080">-->
    <!--    </div>-->

    <SinglePostComments v-if="comentsAreOpen" />
  </article>
</template>

<script setup>
import PostAuthorBar from "@/components/molecules/PostAuthorBar";
import PostContent from "@/components/molecules/PostContent";
import PostFooter from "@/components/molecules/PostFooter";
import SinglePostComments from "@/components/organisms/SinglePostComments";
import {provide, reactive, ref} from "vue";

const comentsAreOpen = ref(false);

const openComents = () => {
  comentsAreOpen.value = !comentsAreOpen.value;
};

const props = defineProps({
  post: Object,
})

provide('post', props.post)

// const showComments = ref(!!props.showComments);
</script>
