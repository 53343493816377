<template>
  <layout-auth>
    <div class="col-start-2 col-end-5 my-auto">
      <div class="flex space-x-2.5 items-center">
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M5.80474 0.528575C6.06509 0.788925 6.06509 1.21103 5.80474 1.47138L2.27614 4.99998L5.80474 8.52858C6.06509 8.78892 6.06509 9.21104 5.80474 9.47138C5.54439 9.73173 5.12228 9.73173 4.86193 9.47138L0.861929 5.47138C0.601579 5.21103 0.601579 4.78892 0.861929 4.52858L4.86193 0.528575C5.12228 0.268226 5.54439 0.268226 5.80474 0.528575Z"
              fill="#878787"
          />
        </svg>
        <router-link to="/" class="text-grayscale-500 text-xs">Wróć do strony głównej</router-link>
      </div>
      <h1 class="text-grayscale-700 text-2xl font-bold mt-9">
        Lorem ipsum Dolor sit Amet, opcjonalny tekst powitalny
      </h1>
      <Form
          action=""
          class="mt-14 flex flex-col border-b border-grayscale-200 pb-10"
          @submit="handleRegister"
          :validation-schema="state.schema"
      >
        <div>
          <label for="user_name" class="text-grayscale-700 text-xs font-bold mb-2">
            Login
          </label>
          <Field name="user_name" type="email" v-model="state.user_name" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2 mb-6" />
          <ErrorMessage name="user_name" class="" />
        </div>

        <div>
          <label for="email" class="text-grayscale-700 text-xs font-bold mb-2">
            Adres e-mail
          </label>
          <Field name="email" type="email" v-model="state.email" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2 mb-6" />
          <ErrorMessage name="email" class="" />
        </div>

        <div>
          <label for="password" class="text-grayscale-700 text-xs font-bold mb-2">
            Hasło
          </label>
          <Field name="password" v-model="state.password" type="password" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2 mb-6" />
          <ErrorMessage name="password" class="" />
        </div>

        <div>
          <label for="password_confirmation" class="text-grayscale-700 text-xs font-bold mb-2">
            Powtórz hasło
          </label>
          <Field name="password_confirmation" v-model="state.password_confirmation" type="password" class="w-full font-sm leading-6 font-medium border-2 border-grayscale-700 rounded-lg p-2" />
          <ErrorMessage name="password_confirmation" class="" />
        </div>

        <div class="form-group">
          <div v-if="state.message" class="alert alert-danger mt-2 text-error-700 text-sm font-medium" role="alert">
            {{ state.message }}
          </div>
        </div>

        <button type="submit" class="btn-primary loader mt-10" :disabled="state.loading">
          <LoadingIndicator :loading="state.loading" />
          <span>Załóż konto</span>
        </button>
      </Form>
      <p class="text-grayscale-700 leading-6 mt-10">
        <router-link to="/login" class="text-primary-500 font-bold text-sm">
          Zaloguj się
        </router-link>
        &nbsp;jeżeli już posiadasz konto na oaza-memow.pl
      </p>
    </div>
  </layout-auth>
</template>

<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import axios from "axios";
import LayoutAuth from "@/layouts/LayoutAuth"
import * as yup from 'yup'
import {onBeforeMount, ref} from "vue";
import {useAuth} from "@/stores/auth";
import {useRouter} from "vue-router";
import LoadingIndicator from "@/components/atoms/LoadingIndicator";

const auth = useAuth()
const router = useRouter()

const state = ref({
  user_name: localStorage.getItem('register:user_name_placeholder') ?? '',
  email: localStorage.getItem('register:email_placeholder') ?? '',
  password: '',
  password_confirmation: '',
  loading: false,
  message: '',
  schema: yup.object().shape({
    user_name: yup.string()
        .min(5, "Nazwa użytkownika jest za krótka!")
        .max(255, "Nazwa użytkownika jest za długa!")
        .required("Nie podano nazwy użytkownika!"),
    email: yup.string()
        .email("Niewłaściwy format!")
        .min(3, "E-mail jest za krótki!")
        .max(255, "E-mail jest za długi!")
        .required("Nie podano e-mail!"),
    password: yup.string()
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).+$/, 'Hasło musi zawierać duże i małe litery oraz cyfry!')
        .min(8, "Hasło jest za krótkie!")
        .max(255, "Hasło jest za długie!")
        .required("Nie podano hasła!"),
    password_confirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'Hasła muszą się zgadzać!')
  }),
})

onBeforeMount(() => {
  if(auth.isLoggedIn){
    router.push('/')
  }
})

const handleRegister = async (values) => {
  state.value.loading = true

  localStorage.setItem('register:user_name_placeholder', values.user_name)
  localStorage.setItem('register:email_placeholder', values.email)

  if(values.password !== values.password_confirmation) return

  await axios.post(`${process.env.VUE_APP_API_URL}/auth/register`, {
    user_name: values.user_name,
    email: values.email,
    password: values.password,
    password_confirmation: values.password_confirmation,
  },{
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(({ status }) => {
    if(status === 200){
      state.value.loading = false
      router.push('/login')
    }
  }).catch(({ response }) => {
    state.value.message = response.data.message
    state.value.loading = false
  })
}
</script>

<style scoped>

</style>