<template>
  <header class="flex items-center justify-between">
    <a
      class="flex items-center"
      href="#"
      :aria-label="`Otwórz profil użytkownika: {{ username }}`"
    >
      <UserAvatar
          class="w-8"
          :src="avatar"
          :alt="`Zdjęcie profilowe użytkownika: ${ username }`"
      />

      <div class="flex items-center">
        <UserName name="Test" />
        <PostCreationTime time="3h temu" />
      </div>
    </a>
    <MoreOptions />
  </header>
</template>

<script setup>
import UserAvatar from "@/components/atoms/UserAvatar";
import UserName from "@/components/atoms/UserName";
import PostCreationTime from "@/components/atoms/PostCreationTime";
import MoreOptions from "@/components/molecules/DropdownOptions";
import {inject} from "vue";

const { author: { id, username, avatar } } = inject('post')
</script>
