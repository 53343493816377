import { openDB } from "idb";

const dbPromise = openDB('oaza-memow', 1, {
    upgrade(db) {
        db.createObjectStore('categories', {
            keyPath: 'id',
            autoIncrement: true,
        });
    },
});

export async function get(db, key) {
    return (await dbPromise).get(db, key);
}

export async function set(db, key, val) {
    return (await dbPromise).put(db, val, key);
}

export async function keys(db) {
    return (await dbPromise).getAllKeys(db);
}
export async function getAll(db) {
    return (await dbPromise).getAll(db);

}