<template>
  <figure>
    <svg
      width="272"
      height="67"
      viewBox="0 0 272 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_931_6328)">
        <path
          d="M21.4912 49.2439C20.4965 49.2439 19.5355 49.3754 18.6156 49.6189C19.1902 56.7901 25.1395 62.4321 32.3974 62.4321C32.4747 62.4321 32.5495 62.4272 32.6268 62.4272C32.721 61.8379 32.7692 61.2365 32.7692 60.6229C32.7717 54.338 27.7206 49.2439 21.4912 49.2439Z"
          fill="#1675BB"
        />
        <path
          d="M28.6356 61.9061C29.8332 62.247 31.096 62.4345 32.3998 62.4345C40.0392 62.4345 46.2323 56.1886 46.2323 48.4842C46.2323 47.2106 46.0585 45.9761 45.7422 44.8024C36.4561 45.058 28.9688 52.558 28.6356 61.9061Z"
          fill="#3A5EA9"
        />
        <path
          d="M32.4071 37.5995C33.5225 36.5743 35.5797 36.1044 36.4923 35.9388C36.4706 35.5468 36.4465 35.1547 36.4151 34.7627C35.8211 34.7627 34.6477 34.7822 32.3129 34.8625C32.7789 34.0078 35.2489 33.3455 36.2654 33.102C36.2219 32.6856 36.1736 32.2692 36.1205 31.8528C35.4928 31.865 34.3604 31.8869 32.3129 31.9258C33.0517 31.0517 34.8771 30.5403 35.8911 30.3138C35.1354 25.7676 33.6288 21.4065 30.6638 18.0461C28.4739 17.7612 27.4839 18.2945 27.4839 18.2945C27.4839 18.2945 32.89 35.0671 29.0268 50.0328C30.5817 51.696 30.5817 51.696 30.5817 51.696C30.5817 51.696 32.5012 48.3697 36.5817 46.2488C36.6034 43.653 36.6734 40.6774 36.5672 37.5849C35.9515 37.5898 34.7515 37.5946 32.4071 37.5995Z"
          fill="#1A6032"
        />
        <path
          d="M44.6557 0.301941C40.9446 0.301941 40.0271 5.43258 39.5322 5.32543C39.0372 5.21829 39.7446 1.6193 39.9209 0.978883C31.4413 3.90093 28.4014 10.5291 28.4014 10.5291C28.4014 10.5291 23.7174 5.1842 14.7814 4.48778C15.1122 5.06489 16.684 8.37167 16.2325 8.60057C15.781 8.82946 13.6225 4.09087 10.0297 5.02349C6.43698 5.95611 0.0555329 13.8286 0 15.7572C1.38591 14.1841 2.94566 12.1411 6.46837 11.2255C9.99109 10.31 16.4232 11.1988 18.985 16.2369C22.2928 18.6159 29.7439 18.8302 29.7439 18.8302C29.7439 18.8302 36.1398 16.5485 38.7547 13.4146C39.9909 7.89197 45.9981 5.41553 49.6367 5.41553C53.2753 5.41553 55.289 7.00074 57.0226 8.17687C56.4938 6.3238 48.3667 0.301941 44.6557 0.301941Z"
          fill="#288E45"
        />
        <path
          d="M29.7463 18.8326C29.7463 18.8326 28.7926 13.0956 21.3367 13.0956C13.8808 13.0956 9.07599 17.4958 9.07599 17.4958C9.07599 17.4958 16.3001 15.6427 18.7025 17.1938C15.1339 19.2076 11.5291 19.1906 9.40918 20.0453C7.29169 20.9 0.970603 27.0874 9.4285 35.593C9.4285 35.593 8.18022 32.9802 8.18022 30.6523C8.18022 28.3244 8.93353 21.9324 15.3415 22.5972C21.7496 23.2619 22.7153 22.5485 23.8695 21.2676C25.0212 19.9844 25.6586 18.4649 29.7463 18.8326Z"
          fill="#3FA146"
        />
        <path
          d="M29.7487 18.8326C29.7487 18.8326 31.0501 13.1638 38.4915 13.6241C45.9329 14.0843 50.4625 18.7742 50.4625 18.7742C50.4625 18.7742 43.3615 16.4779 40.8698 17.8781C44.3104 20.1086 47.908 20.3131 49.9723 21.2969C52.0367 22.2806 57.9691 28.8479 49.0114 36.8154C49.0114 36.8154 50.4166 34.283 50.5567 31.9599C50.6967 29.6369 50.3321 23.2108 43.8975 23.4787C37.463 23.7465 36.5406 22.9771 35.4662 21.6256C34.3918 20.2742 33.8485 18.7157 29.7487 18.8326Z"
          fill="#3FA146"
        />
        <path
          d="M11.6885 12.0267C11.6885 12.0267 15.5154 15.7937 18.985 16.2393C20.1922 18.4649 17.4446 18.8326 17.4446 18.8326C17.4446 18.8326 13.0333 16.5169 11.6885 12.0267Z"
          fill="#288E45"
        />
        <path
          d="M44.9261 7.46338C44.9261 7.46338 42.4247 12.5891 38.8006 14.4252C41.4613 15.4479 41.5797 15.4284 41.5797 15.4284C41.5797 15.4284 44.5253 12.4065 44.9261 7.46338Z"
          fill="#288E45"
        />
        <path
          d="M36.6903 30.74L37.5716 35.8901C42.3837 37.9331 45.7712 42.7375 45.7712 48.3234C45.7712 55.7576 39.7736 61.8063 32.4022 61.8063C25.0308 61.8063 19.0333 55.7576 19.0333 48.3234C19.0333 41.873 23.5484 36.4696 29.558 35.1499C29.4469 33.0849 29.2393 31.5168 29.0751 30.5306C20.7596 32.1085 14.4458 39.4842 14.4458 48.3234C14.4458 58.3096 22.5005 66.4329 32.4022 66.4329C42.304 66.4329 50.3587 58.3096 50.3587 48.3234C50.3587 39.83 44.5277 32.6856 36.6903 30.74Z"
          fill="#FDC31F"
        />
        <path
          d="M29.7076 18.6549L40.9011 17.0234L40.9905 19.232L29.7076 19.0469V18.6549Z"
          fill="#030405"
        />
        <path
          d="M40.9012 17.0234C41.145 16.9869 41.3648 17.1719 41.3768 17.4203L41.4348 18.7961C41.4444 19.0348 41.2561 19.232 41.0195 19.232H40.9881L40.9012 17.0234Z"
          fill="#FDC31F"
        />
        <path
          d="M8.79108 10.6898L41.512 6.00238L41.7052 8.1233L40.6452 8.2134L40.9011 10.5048L39.916 10.6119L39.9812 11.5397L39.0492 11.6687L39.1193 12.7742L33.7978 13.6655L33.6191 12.6208L32.7257 12.6671L32.5954 11.6468L31.5233 11.6346L31.3591 10.6241L30.263 10.8384L30.0746 9.83514H29.133L29.0147 8.75885L27.9547 8.89034L27.9427 9.75722L26.9431 10.0299L27.071 11.2304L26.1874 11.4325L26.2115 12.37L25.3157 12.5965L25.3761 13.6655L24.3282 13.7726L24.3741 14.9244L19.0164 15.6598L18.9319 14.7344L18.024 14.7417L17.9443 13.6289L16.9447 13.6119L16.7661 12.8205L15.6361 12.9763L15.4912 12.0267L14.4144 12.1192L14.3395 11.0819L8.82247 10.9139L8.79108 10.6898Z"
          fill="#030405"
        />
        <path
          d="M22.9158 10.7775L21.7786 10.9041L21.9065 12.0266L20.8538 12.1508L20.9576 13.2198L22.0442 13.1127L21.9452 12.0851L23.0148 11.9365L22.9158 10.7775Z"
          fill="#F6F5F5"
        />
        <path
          d="M24.152 11.7515L23.0148 11.8757L23.1452 12.9983L22.09 13.1225L22.1963 14.1914L23.2804 14.0867L23.1814 13.0567L24.251 12.9106L24.152 11.7515Z"
          fill="#F6F5F5"
        />
        <path
          d="M24.152 11.7515L23.8792 10.5827L24.9801 10.4049L25.1926 11.5859L24.152 11.7515Z"
          fill="#F6F5F5"
        />
        <path
          d="M37.5112 8.6712L36.374 8.79783L36.502 9.92038L35.4493 10.0446L35.5555 11.1136L36.6396 11.0064L36.5406 9.97882L37.6102 9.83028L37.5112 8.6712Z"
          fill="#F6F5F5"
        />
        <path
          d="M38.7474 9.6452L37.6102 9.76939L37.7406 10.8919L36.6855 11.0161L36.7917 12.0851L37.8758 11.9804L37.7768 10.9528L38.8464 10.8043L38.7474 9.6452Z"
          fill="#F6F5F5"
        />
        <path
          d="M38.7474 9.64522L38.4746 8.4764L39.578 8.29865L39.7881 9.47964L38.7474 9.64522Z"
          fill="#F6F5F5"
        />
        <path
          d="M75.8676 63.4037H70.7827L69.0153 59.9118H55.1683L53.582 63.4037H48.4247L62.1292 35.3641L75.8676 63.4037ZM66.9968 55.6943L62.1292 45.4014L56.8995 55.6943H66.9968Z"
          fill="#FDC31F"
        />
        <path
          d="M98.4791 36.5281L85.0643 59.1131H97.9745V63.4037H77.3115L90.6176 40.8186L77.7799 40.7091V36.4891L98.4791 36.5281Z"
          fill="#FDC31F"
        />
        <path
          d="M127.327 63.4037H122.242L120.475 59.9118H106.628L105.042 63.4037H99.8843L113.589 35.3641L127.327 63.4037ZM118.456 55.6943L113.589 45.4014L108.359 55.6943H118.456Z"
          fill="#FDC31F"
        />
        <path
          d="M163.88 60.0993C163.88 61.1147 163.59 61.9231 163.008 62.5197C162.426 63.1163 161.659 63.4182 160.705 63.4182C158.923 63.4182 157.89 62.1812 157.607 59.7072L155.632 43.1075C154.393 48.4451 153.645 51.674 153.387 52.7941C152.044 58.2097 151.088 61.3728 150.521 62.2835C150.057 63.0384 149.178 63.4158 147.886 63.4158C146.853 63.4158 146.054 63.1747 145.486 62.6926C144.919 62.2104 144.492 61.385 144.209 60.2137L140.143 43.1075L137.975 59.3152C137.613 62.023 136.517 63.3768 134.684 63.3768C133.781 63.3768 133.045 63.0846 132.477 62.4978C131.91 61.9109 131.625 61.1122 131.625 60.0968C131.625 59.6804 131.702 59.0425 131.857 58.1829L135.225 39.2407C135.483 37.731 135.935 36.6303 136.579 35.9412C137.224 35.2521 138.192 34.9063 139.484 34.9063C141.783 34.9063 143.241 36.1555 143.859 38.6563L148.041 55.256L151.952 38.6173C152.546 36.1433 153.978 34.9063 156.25 34.9063C157.593 34.9063 158.599 35.2716 159.271 35.9996C159.942 36.7302 160.406 37.8868 160.664 39.4745L163.723 58.5725C163.829 59.2275 163.88 59.734 163.88 60.0993Z"
          fill="#1A6032"
        />
        <path
          d="M185.567 51.8591C185.567 52.9013 185.296 53.605 184.753 53.9679C184.34 54.2284 183.604 54.3575 182.546 54.3575H171.626C171.91 56.8047 173.446 58.0295 176.235 58.0295C177.396 58.0295 178.673 57.6983 180.069 57.0336C181.465 56.3688 182.329 56.0376 182.665 56.0376C183.386 56.0376 183.976 56.2592 184.427 56.7024C184.879 57.1456 185.106 57.7178 185.106 58.4215C185.106 59.6975 184.241 60.8566 182.51 61.8964C180.677 63.0165 178.483 63.5765 175.926 63.5765C172.905 63.5765 170.387 62.5416 168.373 60.4719C166.36 58.4021 165.353 55.8039 165.353 52.6797C165.353 49.5288 166.333 46.9062 168.296 44.8097C170.257 42.7131 172.698 41.666 175.614 41.666C178.48 41.666 180.856 42.6619 182.739 44.6538C184.623 46.6457 185.567 49.0466 185.567 51.8591ZM179.603 50.3348C179.526 49.2682 179.106 48.3892 178.345 47.6976C177.582 47.0085 176.686 46.6627 175.653 46.6627C174.62 46.6627 173.729 46.9939 172.98 47.6587C172.232 48.3234 171.754 49.2147 171.548 50.3348H179.603Z"
          fill="#1A6032"
        />
        <path
          d="M218.21 58.8112C218.21 61.8063 217.189 63.3014 215.151 63.3014C213.138 63.3014 212.131 61.8038 212.131 58.8112V51.8591C212.131 48.735 211.201 47.1717 209.342 47.1717C207.302 47.1717 206.283 48.7082 206.283 51.7812V58.8112C206.283 61.8063 205.262 63.3014 203.224 63.3014C201.184 63.3014 200.165 61.8038 200.165 58.8112V51.8591C200.165 48.735 199.247 47.1717 197.415 47.1717C195.374 47.1717 194.355 48.7082 194.355 51.7812V58.8112C194.355 61.8063 193.334 63.3014 191.296 63.3014C189.256 63.3014 188.237 61.8038 188.237 58.8112V46.4314C188.237 43.4631 189.256 41.9801 191.296 41.9801C192.508 41.9801 193.477 42.6303 194.201 43.933C195.439 42.5792 197.067 41.9022 199.081 41.9022C201.688 41.9022 203.714 42.8665 205.16 44.7926C206.889 42.8665 208.941 41.9022 211.317 41.9022C213.459 41.9022 215.137 42.5792 216.351 43.933C217.59 45.3649 218.21 47.3835 218.21 49.9866V58.8112Z"
          fill="#1A6032"
        />
        <path
          d="M241.679 52.6408C241.679 55.8428 240.718 58.48 238.794 60.5498C236.869 62.6196 234.399 63.6545 231.379 63.6545C228.383 63.6545 225.913 62.6196 223.964 60.5498C222.016 58.48 221.04 55.8428 221.04 52.6408C221.04 49.4387 222.013 46.8015 223.964 44.7317C225.913 42.662 228.385 41.6271 231.379 41.6271C234.399 41.6271 236.872 42.662 238.794 44.7317C240.716 46.8015 241.679 49.4362 241.679 52.6408ZM235.406 52.5604C235.406 51.0239 235.018 49.782 234.24 48.8299C233.463 47.8802 232.502 47.4054 231.36 47.4054C230.191 47.4054 229.225 47.8729 228.46 48.8104C227.694 49.7479 227.313 50.9971 227.313 52.5604C227.313 54.1481 227.69 55.4191 228.441 56.3688C229.191 57.3185 230.164 57.7933 231.36 57.7933C232.552 57.7933 233.525 57.3185 234.279 56.3688C235.03 55.4191 235.406 54.1505 235.406 52.5604ZM235.793 35.5346C235.793 36.343 235.457 37.0711 234.786 37.7213L233.197 39.2845C232.371 40.093 231.673 40.4948 231.106 40.4948C230.486 40.4948 229.983 40.2927 229.597 39.8884C229.211 39.4842 229.015 38.9704 229.015 38.3446C229.015 37.8503 229.235 37.2781 229.674 36.6255L231.145 34.4388C231.712 33.606 232.449 33.1896 233.352 33.1896C234.049 33.1896 234.629 33.4185 235.095 33.8739C235.561 34.3292 235.793 34.882 235.793 35.5346Z"
          fill="#1A6032"
        />
        <path
          d="M272 45.0629C272 45.5304 271.845 46.2098 271.536 47.0937L266.85 60.5279C266.152 62.5319 264.991 63.5352 263.363 63.5352C261.454 63.5352 260.227 62.4686 259.684 60.3331L257.165 50.5296L254.804 60.5279C254.341 62.5319 253.177 63.5352 251.318 63.5352C250.361 63.5352 249.613 63.2868 249.072 62.7925C248.529 62.2982 248.051 61.4775 247.64 60.3331L242.799 46.7017C242.568 46.0515 242.452 45.4915 242.452 45.0215C242.452 44.1619 242.737 43.4387 243.304 42.8543C243.871 42.2675 244.569 41.9753 245.395 41.9753C246.918 41.9753 247.964 42.9785 248.531 44.9826L251.745 56.23L254.341 44.9436C254.753 43.0954 255.695 42.1701 257.168 42.1701C258.742 42.1701 259.775 43.1197 260.266 45.0215L263.054 56.0742L266.268 44.593C266.732 42.9006 267.688 42.0532 269.134 42.0532C269.986 42.0532 270.677 42.3332 271.206 42.8933C271.734 43.4558 272 44.179 272 45.0629Z"
          fill="#1A6032"
        />
      </g>
      <defs>
        <clipPath id="clip0_931_6328">
          <rect
            width="272"
            height="66.1333"
            fill="white"
            transform="translate(0 0.301941)"
          />
        </clipPath>
      </defs>
    </svg>
  </figure>
</template>
