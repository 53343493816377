import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import { VueCookieNext } from "vue-cookie-next/dist/vue-cookie-next.esm";

import "./assets/scss/global.scss"

import App from './App'
import HomeView from "@/views/HomeView"
import RegisterView from "@/views/RegisterView"
import LoginView from "@/views/LoginView"
import ProfileView from '@/views/ProfileView'
import PostView from '@/views/PostView'
import SettingsView from '@/views/SettingsView'
import ResetPasswordView from '@/views/ResetPasswordView'
import {pinia} from "@/stores/store";

const routes = [
    { path: '/', component: HomeView },
    { path: '/login', component: LoginView },
    { path: '/register', component: RegisterView },
    { path: '/profile/:username', component: ProfileView },
    { path: '/post/:id', name: "SinglePost", component: PostView },
    { path: '/settings', component: SettingsView },
    { path: '/reset-password', component: ResetPasswordView },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const app = createApp(App)
    .use(router)
    .use(pinia)
    .use(VueCookieNext)
    .mount("#app");

VueCookieNext.config({ expire: '7d' })