<template>
  <div class="text-grayscale-500 font-regular text-xs mt-8 pb-4">
    <p>
      <a href="#">Regulamin</a><span class="mx-5">|</span><a href="#">Reklama</a
      ><span class="mx-5">|</span><a href="#">Polityka prywatności</a>
    </p>
    <p>Oaza Memów © 2022</p>
    <h6 class="text-xs my-2">
      Realizacja: © 2022
      <a
        href="https://swierczkacper.pl"
        target="_blank"
        aria-label="Świercz Kacper - strony www Piła"
        >Świercz Kacper / strony www Piła</a
      >
    </h6>
  </div>
</template>
