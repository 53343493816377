import {getAll, keys, set} from "@/stores/helpers/idb";

export const populateFromCache = async (DB_NAME) => {
    let [keyss, values] = await Promise.all([keys(DB_NAME), getAll(DB_NAME)])
    return keyss.map((key, index) => ({ id: key, name: values[index] }))
}

export const addCategories = (DB_NAME, values, origin) => {
    let result = []

    values.forEach(category => {
        if(!origin.includes(category)){
            set(DB_NAME, category.id, JSON.stringify(category))
            result.push(category)
        }
    })

    return result
}