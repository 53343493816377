<template>
  <div class="w-full bg-white shadow-card rounded-lg flex p-4 flex-col">
    <CreatePostHeader @TogglePostCreationPanel="toggleCreationPanel" />

    <CreatePostPanel v-if="isPostInCreatedPhase" />
  </div>
</template>

<script setup>
import CreatePostPanel from "@/components/molecules/CreatePostPanel";
import { ref } from "vue";
import CreatePostHeader from "../molecules/CreatePostHeader.vue";

const isPostInCreatedPhase = ref(false);
const toggleCreationPanel = () => {
  isPostInCreatedPhase.value = !isPostInCreatedPhase.value;
};
</script>

<style scoped></style>
