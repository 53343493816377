<template>
  <div>
    <img
        :src="src"
        :alt="alt"
    />
    <button @click="state.expanded = true" v-if="!state.expanded">Pokaż więcej</button>
  </div>
</template>

<script setup>
import {computed, reactive} from "vue";
  import {useRoute} from "vue-router";

  const state = reactive({
    expanded: false,
  })

  defineProps({
    src: String,
    alt: String
  })

</script>

<style lang="postcss" scoped>
div{
  @apply relative;
}

img{
  @apply w-full;
}

button{
  @apply content-['Pokaż_więcej'] absolute bottom-0 left-0 w-full py-6 text-center bg-black/50 text-white font-medium text-sm;
}
</style>